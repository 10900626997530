import apiClient from '../../core/api/api-client';
import { API_ROUTES } from '../../core/constants';
import { reactive } from 'vue';

export const tenantSettings = reactive({
    featureFlags: null,
});

// Getters
export const getters = {
    get isTenantAccountBased() {
        return (
            tenantSettings.featureFlags?.accountSubscriptionsOnboardingFlow ===
            true
        );
    },
    get tenantHasCustomOfferings() {
        return tenantSettings.featureFlags?.customOffering === true;
    },
    /**
     * Returns whether the tenant has exited the MSP business based on the value of the tenant
     * feature flag `accountLevelSubscriptionsMspExit`
     * @returns {boolean}
     */
    get tenantExitedMsp() {
        return Boolean(
            tenantSettings.featureFlags?.accountLevelSubscriptionsMspExit
        );
    },

    get tenantCanDoBillingManagement() {
        return Boolean(tenantSettings.featureFlags?.billingManagement);
    },

    get tenantHasAdhocChargingEnabled() {
        return Boolean(tenantSettings.featureFlags?.adhocChargingQrCode);
    },
};

/**
 * Transforms data by replacing `null` with empty strings
 * @param {Object} data
 * @returns {Object}
 */
function transform(data) {
    const value = {};

    Object.keys(data).forEach((key) => {
        value[key] = data[key] || '';
    });

    return value;
}

/**
 * Returns all support links configured for the tenant
 * @returns {Promise.<Object>}
 */
export const getSupportLinks = () =>
    apiClient
        .get(API_ROUTES.TENANTS.TENANT_SETTINGS.SUPPORT_LINKS)
        .then(transform);

/**
 * Saves support links config
 * @param {Object} payload
 * @returns {Promise.<Object>}
 */
export const saveSupportLinks = (payload) =>
    apiClient.put(
        API_ROUTES.TENANTS.TENANT_SETTINGS.SAVE_SUPPORT_LINKS,
        payload
    );

/**
 * Returns tenant notification settings
 * @returns {Promise.<Object>}
 */
export const getNotificationSettings = () =>
    apiClient.get(API_ROUTES.TENANTS.TENANT_SETTINGS.NOTIFICATIONS);

/**
 * Saves notification settings
 * @param {Object} payload
 * @returns {Promise.<Object>}
 */
export const saveNotificationSettings = (payload) =>
    apiClient.put(
        API_ROUTES.TENANTS.TENANT_SETTINGS.SAVE_NOTIFICATIONS,
        payload
    );

/**
 * Returns the feature flags map
 * @returns {Promise.<Object>}
 */
export const getFeatureFlags = async () => {
    try {
        tenantSettings.featureFlags = await apiClient.get(
            API_ROUTES.TENANTS.TENANT_FEATURES
        );
    } catch (e) {
        return {};
    }
};
