import { API_ROUTES } from '../core/constants';
import { colorService } from '@everon/jolt/services';
import { apiUrl } from '../core/api/apiUrl';
import { reactive } from 'vue';

const getAssetsUrl = () => {
    // local development

    if (!EVERON_CDN_URL) {
        return '';
    }

    // staging and production
    if (EVERON_CDN_URL.startsWith('http')) {
        return `${EVERON_CDN_URL}`;
    }

    // ad-hoc environments
    return `https://${EVERON_CDN_URL}`;
};

// State
export const configuration = reactive({
    tenant: null,
    branding: null,
});

// Getters
export const getters = {
    get tenantId() {
        return configuration.tenant?.tenantId;
    },
};

export const logo = () =>
    configuration?.branding?.images?.logo ||
    `${getAssetsUrl()}/images/everon-logo.png`;
export const icon = () =>
    configuration?.branding?.images?.icon ||
    `${getAssetsUrl()}/images/everon-icon.png`;
export const brandImage = () =>
    configuration?.branding?.images?.brand ||
    `${getAssetsUrl()}/images/everon-brand.jpg`;
export const evboxTenantBranding = () =>
    Boolean(configuration.branding?.evboxTenantBranding);

/**
 *  Limit updating of the properties to branding only
 *  @param {string} key
 *  @param {any} value
 */
export const updateConfigurationBranding = (key, value) => {
    configuration.branding[key] = value;
};
/**
 * Gets branding settings from tenants service
 */
export const getBrandingSettings = async () => {
    if (configuration?.branding?.tenantId) {
        return configuration.branding;
    }

    const headers = {};

    if (getters.tenantId) {
        headers.tenantId = getters.tenantId;
    }

    return await fetch(`${apiUrl}${API_ROUTES.TENANTS.BRANDING.GET}`, {
        referrerPolicy: 'no-referrer-when-downgrade',
        method: 'GET',
        mode: 'cors',
        headers,
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(`Error ${response.status}`);
            }

            return response;
        })
        .then((response) => response.json());
};

/**
 * Sets colors based on branding configuration
 */
export const setupPageBrand = () => {
    const EVERON_PRIMARY_COLOR = '#101D83';

    try {
        const [primaryColor] = configuration.branding.colors;

        colorService.setColorStyles(
            primaryColor,
            colorService.getBrandColorModel()
        );
    } catch (e) {
        // If something goes wrong, apply the default brand colors so things don't break
        colorService.setColorStyles(
            { value: EVERON_PRIMARY_COLOR },
            colorService.getBrandColorModel()
        );
    } finally {
        const headerIconSelector = 'link[rel="icon"]';

        const documentHeaderIcon = document.querySelector(headerIconSelector);

        if (documentHeaderIcon) {
            documentHeaderIcon.href = icon();
        }
    }
};

/**
 * Gets tenant configuration
 * @param {string} tenantId
 */
export const getTenantConfig = async (tenantId) => {
    try {
        return fetch(
            `${apiUrl}${API_ROUTES.TENANTS.TENANT_CONFIG.replace(
                '{tenantId}',
                tenantId
            )}`
        ).then((config) => config.json());
    } catch (e) {
        return {};
    }
};

/**
 * Gets and sets branding configuration, sets up branding colors and gets tenant settings
 * Should be called only once at the start, before initializing the application
 */
export const initializeConfiguration = async () => {
    configuration.branding = await getBrandingSettings();
    const tenantId = configuration.branding.tenantId;

    setupPageBrand();

    configuration.tenant = await getTenantConfig(tenantId);
};
