import { subscriptionState } from '../services/subscription/subscription.service.js';
import {
    state as authState,
    getters as authGetters,
} from '../services/auth.service.js';
import { getBillingInfo } from '../services/account/account.service.js';
import { SUBSCRIPTIONS } from '../core/constants.js';
import { hasPermission } from '../utils/permissions.js';

const { TYPES, STATUS } = SUBSCRIPTIONS;

/**
 * Checks if the user is a new self signed up user
 * @returns {boolean}
 */
export const isSelfSignupOnboarding = () => {
    if (!hasPermission('ACCOUNT_SUBSCRIPTION:UPGRADE')) {
        return false;
    }

    if (subscriptionState.subscription) {
        return false;
    }

    return subscriptionState.subscriptionType === TYPES.ACCOUNT;
};

/**
 * Checks if the user is a new employee
 * @returns {boolean}
 */
export const isEmployeeOnboarding = async () => {
    const { accountId } = authState.profile;
    const billing = await getBillingInfo(accountId);

    const isAccountAccount =
        subscriptionState.subscriptionType === TYPES.ACCOUNT;
    const isBillingIncomplete = Boolean(billing?.billingAddress === null);

    return authGetters.isDriver && isBillingIncomplete && isAccountAccount;
};

/**
 * Checks if the user has an existing inactive subscription
 * @returns {boolean}
 */
export const isOnboardingOnBehalf = () => {
    if (!subscriptionState.subscription) {
        return false;
    }

    const { accountSubscription: { status = null } = {} } =
        subscriptionState.subscription;

    return status !== STATUS.ACTIVATED;
};

export default {
    isEmployeeOnboarding,
    isSelfSignupOnboarding,
    isOnboardingOnBehalf,
};
