import { decodeToken, OktaAuth } from '@okta/okta-auth-js';
import { DEFAULT_PUBLIC_ROUTE_NAME } from '../core/router.auth';
import { configuration, getters } from './config.service';
import { get, set } from './local-store/index.js';
import { resolveApiUrl } from '../core/api/api-client.js';
import { parseJSON } from '../core/api/json-parser.js';

const tokenRenewalAnticipationNumber = 10000;

class OktaWrapper {
    constructor() {
        this.tokenRenewalAnticipation = tokenRenewalAnticipationNumber;
        this.accessTokenContainer = null;
        this.renewTokenRequest = null;

        const {
            baseUrl,
            redirectUri,
            clientId,
            issuer,
            scopes,
            postLogoutRedirectUri,
        } = this.oktaConfiguration;

        this.authClient = new OktaAuth({
            baseUrl,
            redirectUri,
            clientId,
            issuer,
            scopes,
            pkce: true,
            responseType: ['token', 'code'],
            grantType: 'authorization_code',
            display: 'page',
            postLogoutRedirectUri,
            tokenManager: {
                storage: 'sessionStorage',
                autoRenew: true,
            },
        });
    }

    /**
     * Compile OKTA configuration based on tenant configuration
     * @returns {Object} OKTA configuration for current tenant
     */
    get oktaConfiguration() {
        const { clientId, issuer } = configuration.tenant;
        const baseUrl = new URL(issuer).origin;

        // local or prod (no adhoc uri segment)
        let redirectUri = `${location.origin}/callback`;
        let postLogoutRedirectUri = `${location.origin}/${DEFAULT_PUBLIC_ROUTE_NAME}`;

        // EVERON_DEPLOY_ENV = "adbda011"
        if (EVERON_DEPLOY_ENV.length > 1) {
            const adhocId = EVERON_DEPLOY_ENV;

            redirectUri = `${location.origin}/${adhocId}/callback`;
            postLogoutRedirectUri = `${location.origin}/${adhocId}/${DEFAULT_PUBLIC_ROUTE_NAME}`;
        }

        return {
            clientId,
            baseUrl,
            issuer,
            scopes: ['openid', 'everon.permissions', 'profile'],
            redirectUri,
            postLogoutRedirectUri,
        };
    }

    /**
     * Wrapper for the OktaSignIn.authClient.token.renew(config).
     * @returns {Promise} Returns the promise returned by OktaSignIn.authClient.token.renew(config).
     */
    renewToken() {
        const { issuer, scopes } = this.oktaConfiguration;

        const renewPayload = {
            accessToken: '*', // renew() expects a non empty string here
            authorizeUrl: `${issuer}/v1/authorize`,
            scopes,
            issuer,
        };

        return this.authClient.token.renew(renewPayload);
    }

    /**
     * Decode object from access token
     * @param {string} token
     * @returns {Object}
     */
    decodeToken(token) {
        return this.authClient.token.decode(token);
    }

    /**
     * Signs the user In
     * @param {string} email
     * @param {string} password
     * @returns {Promise}
     */
    signIn(email, password) {
        return this.authClient
            .signInWithCredentials({
                username: email,
                password,
            })
            .then((transaction) => {
                if (transaction.status === 'SUCCESS') {
                    return this.authClient.token
                        .getWithoutPrompt({
                            responseType: ['id_token', 'token'],
                            scopes: ['openid', 'email', 'profile'],
                            sessionToken: transaction.sessionToken,
                        })
                        .then((response) => {
                            this.authClient.tokenManager.setTokens(response);
                            this.renewTokenRequest = null;

                            return response;
                        });
                }
            })
            .catch((err) => {
                console.error(err.message);
            });
    }

    /**
     * Signs the user out
     * @returns {Promise}
     */
    signOut() {
        return this.authClient.signOut();
    }

    /**
     * @returns {boolean} if token has expired
     */
    hasTokenExpired() {
        const expiresAt = this.accessTokenContainer?.expiresAt || 0;

        return expiresAt * 1000 - this.tokenRenewalAnticipation <= Date.now();
    }

    /**
     * @returns {boolean} if token has been refreshed
     */
    isAuthorized() {
        return Boolean(this.accessTokenContainer) && !this.hasTokenExpired();
    }

    /**
     * Get a token or refresh the token if it has expired
     * @returns {Promise<string>} OKTA access token to be used as Bearer in requests
     */
    getAccessToken() {
        if (!this.accessTokenContainer || this.hasTokenExpired()) {
            if (this.renewTokenRequest) {
                return this.renewTokenRequest;
            } else {
                this.renewTokenRequest = this.renewToken().then(
                    (tokenContainer) => {
                        this.accessTokenContainer = tokenContainer;
                        this.renewTokenRequest = null;

                        return tokenContainer.accessToken;
                    }
                );

                return this.renewTokenRequest;
            }
        }

        return Promise.resolve(this.accessTokenContainer.accessToken);
    }
}

class OktaService {
    constructor() {
        if (!OktaService.instance) {
            this.oktaWrapperInstance = null;
            OktaService.instance = this;
        }

        return OktaService.instance;
    }

    /**
     * Get instance of Okta token wrapper based on current configuration
     * @returns {OktaWrapper} instance populated with defined configuration
     */
    get oktaWrapper() {
        if (!this.oktaWrapperInstance) {
            this.oktaWrapperInstance = new OktaWrapper();
        }

        return this.oktaWrapperInstance;
    }

    /**
     * Get a token with optional session renewal
     * @returns {Promise<string>} resolves into a token
     */
    async getOktaAccessToken() {
        if (get('ADHOC_USER_OKTA_TOKEN')) {
            return await this.resolveAdhocChargingToken();
        }

        return this.oktaWrapper.getAccessToken();
    }

    /**
     * Get adhoc charging token form local storage and return
     * @returns {Object} decoded token
     */
    getAdhocDecodedAccessToken() {
        let decodedToken = null;

        if (get('ADHOC_USER_OKTA_TOKEN')) {
            const token = get('ADHOC_USER_OKTA_TOKEN');

            decodedToken = decodeToken(token);
        }

        return decodedToken;
    }

    async resolveAdhocChargingToken() {
        const token = get('ADHOC_USER_OKTA_TOKEN');
        const decodedToken = decodeToken(token);

        const expiresAt = decodedToken?.payload.exp || 0;

        if (expiresAt * 1000 - tokenRenewalAnticipationNumber <= Date.now()) {
            const tenantid = getters?.tenantId;

            const url = new URL(resolveApiUrl('/api/adhoc/v1/public/token'));

            try {
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        tenantid,
                    },
                    body: `refresh_token=${get('ADHOC_USER_REFRESH_TOKEN')}`,
                    method: 'POST',
                });

                const {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                } = await parseJSON(response);

                set('ADHOC_USER_OKTA_TOKEN', accessToken);

                if (refreshToken) {
                    set('ADHOC_USER_REFRESH_TOKEN', refreshToken);
                }
            } catch (e) {
                console.error(e);
            }
        }

        return get('ADHOC_USER_OKTA_TOKEN');
    }

    /**
     * Decode a token into a JSON object
     * @returns {Promise<Object>} resolves into an object containing token data
     */
    getOktaTokenDecoded() {
        return this.getOktaAccessToken().then((token) =>
            this.oktaWrapper.decodeToken(token)
        );
    }

    /**
     * Decode user permissions array
     * @returns {Array} user permissions, like accessing business portal or reading transaction data
     */
    getUserPermissions() {
        return this.getOktaTokenDecoded().then((token) => {
            const permissions = token?.payload?.permissions || [];

            return permissions.map((permission) => permission.toUpperCase());
        });
    }

    /**
     *
     * @returns {Promise<string>|null}
     */
    getUserId() {
        try {
            return this.getOktaTokenDecoded().then(
                (token) => token.payload.uid
            );
        } catch (err) {
            console.log(err);
        }

        return null;
    }

    /**
     * Signs the user out
     * @returns {Promise}
     */
    signOut() {
        return this.oktaWrapper.signOut();
    }

    /**
     * Signs the user in
     * @param {string} email
     * @param {string} password
     * @returns {Promise}
     */
    signIn(email, password) {
        return this.oktaWrapper.signIn(email, password);
    }
}

// TODO: fix this somewhere in okta service, so this handler is not needed anymore
export const handleOktaError = (error) => {
    // Okta wrapper throws an error if the user is not authenticated
    console.error(error);
};

const instance = new OktaService();

if (EVERON_SKIP_AUTH === 'true') {
    // used in component tests
    const { id, permissions, access } = JSON.parse(
        localStorage.getItem('EVBox.msw.user')
    );

    console.log('Skipping authentication', id);

    instance.getUserId = () => Promise.resolve(id);
    instance.getOktaAccessToken = () => Promise.resolve('mockedAccessToken');
    instance.getUserPermissions = () => permissions;
    instance.getOktaTokenDecoded = () =>
        Promise.resolve({ payload: { access } });
}

export default instance;
